"use client";
import LiveSessions from "../LiveSessions";
import OurAstrologers from "../OurAstrologers";
import { GetLiveAstroData } from "../helper";
import { useEffect, useState } from "react";
import { LiveDataType } from "../../../types/common.types";
import OurAstrologersChat from "../OurAstrologersChat";

export default function Sessions() {
  const [sessions, setSessions] = useState<LiveDataType | null>(null);
  useEffect(() => {
    const fetchData = async () => {
      const sessionData = await GetLiveAstroData();
      setSessions(sessionData);
    };
    fetchData();
  }, []);
  return (
    <>
      <OurAstrologersChat sessions={sessions?.astrologersChat ?? []} />
      <OurAstrologers sessions={sessions?.astrologers ?? []} />
      <LiveSessions sessions={sessions?.liveSessions ?? []} />
    </>
  );
}
